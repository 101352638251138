<template>
  <v-dialog :value="viewProcessing" max-width="400" persistent>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>
        <v-progress-linear
          indeterminate
          reverse
          color="primary darken-2"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Procesando",
    },
    viewProcessing: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped></style>
