import Vue from "vue";
import axios from "axios";
import axiosRetry from "axios-retry";
import store from "@/store/index";

const apiReport = axios.create({
  baseURL: `${Vue.prototype.$Settings.all.coreApi.url}/reports`,
  withCredentials: true, //true para soportar cookies de respuests
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
    "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
  },
});

axiosRetry(apiReport, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

apiReport.interceptors.request.use((configuration) => {
  const currentUserAccount = Vue.prototype.$Authentication.getAccount();
  const sessionId = Vue.prototype.$Authentication.getSessionId();
  if (currentUserAccount) {
    configuration.headers.CurrentUserToken = currentUserAccount.token;
    if (sessionId) configuration.headers.sessionId = sessionId;
  }

  return configuration;
});

apiReport.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 500) {
      store.dispatch(
        "notifications/addError",
        "Servicio no disponible. Vuelva a intentar"
      );
    }

    if (
      (error.response && error.response.status == 401) ||
      (error.response && error.response.status == 403)
    ) {
      store.dispatch("currentUser/logout");
      store.dispatch("notifications/addInfo", "Su sesión ha expirado");
      store.dispatch("notifications/setMiddleware");
    }
    return Promise.reject(error);
  }
);

export default {
  getClaimstatistics(wfStatuses, groupBySearch) {
    const headers = {
      groupLastDate: groupBySearch,
    };

    return apiReport.get(`/claimstatistics/states/${wfStatuses.join(",")}`, {
      headers: headers,
    });
  },
  getClaimstatisticsAll(groupBySearch) {
    const headers = {
      groupLastDate: groupBySearch,
    };

    return apiReport.get(`/claimstatistics/statesall`, {
      headers: headers,
    });
  },
  getDefenders(wfStatuses, groupBySearch) {
    const headers = {
      groupLastDate: groupBySearch,
    };

    return apiReport.get(`/claimstatistics/defenders/${wfStatuses.join(",")}`, {
      headers: headers,
    });
  },
  getDefendersAll(groupBySearch) {
    const headers = {
      groupLastDate: groupBySearch,
    };

    return apiReport.get(`/claimstatistics/defendersall`, {
      headers: headers,
    });
  },
  getTypologies(wfStatuses, groupBySearch) {
    const headers = {
      groupLastDate: groupBySearch,
    };

    return apiReport.get(
      `/claimstatistics/typologies/${wfStatuses.join(",")}`,
      {
        headers: headers,
      }
    );
  },
  getTypologiesAll(groupBySearch) {
    const headers = {
      groupLastDate: groupBySearch,
    };

    return apiReport.get(`/claimstatistics/typologiesall`, {
      headers: headers,
    });
  },
};
